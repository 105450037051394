import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Layout, SliceZone } from '../components';

class GeneralTemplate extends Component {
  render() {
    const {
      data: {
        page: {
          data: pageData,
        },
      },
      location,
    } = this.props;
    const {
      metaTitle,
      metaDescription,
      openGraphImage,
      body,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    return (
      <Layout
        location={location}
        seoData={seoData}
      >
        <SliceZone
          allSlices={body}
          location={location}
        />
      </Layout>
    );
  }
}

export default GeneralTemplate;

export const pageQuery = graphql`
  query GeneralTemplateQuery($uid: String!) {
    page: prismicGeneralTemplate(uid: { eq: $uid }) {
      uid,
      data {
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        body {
          ... on PrismicGeneralTemplateBodyEnquiryForm {
            id
            sliceType: slice_type
            primary {
              formTitle: form_title {
                text
              }
              formBody: form_body {
                html
              }
            }
          }
        }
      }
    }
  }
`;
